import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import VueBus from 'vue-bus';

import 'amfe-flexible'

import axios from 'axios'
Vue.prototype.$axios = axios

Vue.config.productionTip = false

import '@/assets/css/reset.css';

import preview from 'vue-photo-preview' 
import 'vue-photo-preview/dist/skin.css' 
Vue.use(preview);
Vue.use(VueBus);


Vue.directive('title', {
  inserted: function (el, binding) {
    document.title = el.dataset.title
  }
})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
