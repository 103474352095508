import Vue from 'vue'
import Vuex from 'vuex'

//挂载Vuex
Vue.use(Vuex)

//创建VueX对象
const store = new Vuex.Store({
    state:{
        isPositionFail: true,
        watermarkConfig: null, //水印标准
        uploadSuccessCount: 0, //成功上传的数量
        uploadMediaMonitor: null, //监测数据上传的数据结构
    },
    mutations: {
        setPositionFailStatus(state, status) {
            state.isPositionFail = status;
        },
        setUploadSuccessCount(state,count) {
            state.uploadSuccessCount = count;
        },
        setWatermarkConfig(state, config) {
            state.watermarkConfig = config
        },
        setUploadMonitorData(state,data) {
            state.uploadMediaMonitor = data;
        }
    }
})

export default store