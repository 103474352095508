<template>
  <div>
    <div class="map-container" id="mapContainer"></div>
  </div>
</template>

<script>
import Vue from "vue";
import { Toast,Notify } from "vant";
Vue.use(Toast);
Vue.use(Notify);
export default {
  name: "GDMap",
  data() {
    return {
      locationTimer: null,
    };
  },
  props: ["isLocation","locationThreshold", "jobStatus", "lat", "lng"],
  methods: {
    initMap() {
      const that = this;
      // 地图定位相关
      AMap.plugin("AMap.Geolocation", function () {
        var geolocation = new AMap.Geolocation({
          // 是否使用高精度定位，默认：true
          enableHighAccuracy: true,
          timeout: 10000,
          noIpLocate: 0,
          buttonOffset: new AMap.Pixel(10, 20),
          zoomToAccuracy: false,
          showButton: false,
          buttonPosition: "RB",
          showCircle: false,
          // circleOptions: {
          //   radius: 30 //圆半径30米
          // }
        });

        getCurrentPosition();
        //获取当前位置信息
        function getCurrentPosition() {
          geolocation.getCurrentPosition((status, result) => {
            if (status == "complete") {
              // data是具体的定位信息
              if (that.jobStatus != 99 && that.lat && that.lng && that.isLocation) {
                //判断两点直接的距离
                const distance = that.caculateLL(
                  that.lat,
                  that.lng,
                  result.position.lat,
                  result.position.lng
                );
                if (distance > Number(that.locationThreshold)) {
                  // Toast("未到达指定地点，您上传的作业可能审核不通过！");
                  Notify({ type: 'danger', message: '未到达指定地点，您上传的作业可能审核不通过',duration: 3000 });
                }
              }

              //暂时存储到本地localStorage
              window.localStorage.setItem("lat", result.position.lat);
              window.localStorage.setItem("lng", result.position.lng);
              that.$store.commit("setPositionFailStatus", false);
            } else {
              // Toast("定位失败，请查看系统定位是否为打开状态");
              console.log(result);
              that.$store.commit("setPositionFailStatus", true);
            }
            setTimeout(() => {
              getCurrentPosition();
            }, 1000 * 10);
          });
        }
      });
    },
    //校验两点之间的距离--单位米
    caculateLL(lat1, lng1, lat2, lng2) {
      var radLat1 = (lat1 * Math.PI) / 180.0;
      var radLat2 = (lat2 * Math.PI) / 180.0;
      var a = radLat1 - radLat2;
      var b = (lng1 * Math.PI) / 180.0 - (lng2 * Math.PI) / 180.0;
      var s =
        2 *
        Math.asin(
          Math.sqrt(
            Math.pow(Math.sin(a / 2), 2) +
              Math.cos(radLat1) *
                Math.cos(radLat2) *
                Math.pow(Math.sin(b / 2), 2)
          )
        );
      s = s * 6378.137;
      s = Math.round(s * 10000) / 10;
      return s;
    },
  },
  created() {
    this.map = null;
  },
  mounted() {
    //高德地图初始化
    this.initMap();
  },
};
</script>

<style scoped>
.map-container {
  width: 100%;
  height: 0px;
}
</style>