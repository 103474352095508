var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"title",rawName:"v-title"}],attrs:{"data-title":"现场作业"}},[(_vm.requirement)?_c('div',{staticClass:"require-wrapper"},[_vm._m(0),_c('div',{staticClass:"require-content"},[_vm._v(_vm._s(_vm.requirement))])]):_vm._e(),_c('div',{staticClass:"main-wrapper"},[_c('AMap',{attrs:{"isLocation":_vm.isLocation,"locationThreshold":_vm.locationThreshold,"jobStatus":_vm.jobStatus,"lat":_vm.lat,"lng":_vm.lng}}),_c('div',_vm._l((_vm.monitorList),function(media,idx){return _c('div',{key:idx,staticClass:"task-card__wrapper"},[_c('h2',{staticClass:"media-title"},[_vm._v("媒体"+_vm._s(idx + 1))]),_vm._l((media.fileStanArr),function(item,index){return _c('div',{key:index},[(item.captureStandardModel.fileTypeValue == 1)?_c('div',{class:[
              'item',
              'mb-md',
              item.file && item.file.fileAuditStatusValue < 0
                ? 'warning'
                : '' ]},[_c('h4',[_c('div',{staticClass:"circle"}),_vm._v(" "+_vm._s(item.captureStandardModel.exampleName)+" ")]),_c('div',{staticClass:"file-wrapper"},[_c('img-uploader',{class:[
                  'image-box',
                  item.captureStandardModel.orientationValue == 1
                    ? ''
                    : 'vertical' ],attrs:{"isOperate":_vm.valid == 1 || (_vm.valid == 2 && _vm.jobStatus == 5),"fileDetail":item.file,"isAsyncFile":_vm.isAsyncFile,"db":_vm.db,"orientation":item.captureStandardModel.orientationValue,"projectId":_vm.projectId,"taskId":_vm.taskId,"mediaIndex":media.mediaSequence,"fileIndex":index + 1,"token":_vm.token,"fileSequence":index + 1,"index":(_vm.taskId + "-" + (media.mediaSequence) + "-" + (index + 1))}})],1),(item.file && item.file.fileAuditStatusValue < 0)?_c('p',{staticClass:"warning-desc"},[_vm._v(" 审核意见:"+_vm._s(item.file.fileAuditStatusMeans)+" ")]):_vm._e(),(
                item.file &&
                item.file.fileAuditStatusValue < 0 &&
                item.file.obj.auditOpinion != ''
              )?_c('p',{staticClass:"warning-desc"},[_vm._v(" 审核意见:"+_vm._s(item.file.obj.auditOpinion)+" ")]):_vm._e(),_c('p',{staticClass:"ad-img_tips"},[_vm._v(" （"+_vm._s(item.captureStandardModel.remark)+"） ")])]):_c('div',{class:[
              'item',
              'mb-md',
              item.file && item.file.fileAuditStatusValue < 0
                ? 'warning'
                : '' ]},[_c('h4',[_c('div',{staticClass:"circle"}),_vm._v(" "+_vm._s(item.captureStandardModel.exampleName)+" ")]),_c('div',{staticClass:"file-wrapper"},[_c('video-uploader',{staticClass:"image-box",attrs:{"isOperate":_vm.valid == 1 || (_vm.valid == 2 && _vm.jobStatus == 5),"fileDetail":item.file,"isAsyncFile":_vm.isAsyncFile,"db":_vm.db,"projectId":_vm.projectId,"taskId":_vm.taskId,"mediaIndex":media.mediaSequence,"fileIndex":index + 1,"token":_vm.token,"fileSequence":index + 1,"index":(_vm.taskId + "-" + (media.mediaSequence) + "-" + (index + 1))}})],1),(item.file && item.file.fileAuditStatusValue < 0)?_c('p',{staticClass:"warning-desc"},[_vm._v(" 审核结论:"+_vm._s(item.file.fileAuditStatusMeans)+" ")]):_vm._e(),(
                item.file &&
                item.file.fileAuditStatusValue < 0 &&
                item.file.obj.auditOpinion != ''
              )?_c('p',{staticClass:"warning-desc"},[_vm._v(" 审核意见:"+_vm._s(item.file.obj.auditOpinion)+" ")]):_vm._e(),_c('p',{staticClass:"ad-img_tips"},[_vm._v(" （"+_vm._s(item.captureStandardModel.remark)+"） ")])])])}),_c('div',{staticClass:"item mb-md"},[_c('h4',[_vm._v("媒体情况")]),_c('div',{class:_vm.isUploadDisable ? 'filter-disabled' : ''},[_c('van-checkbox-group',{staticClass:"media-status--wrapper",attrs:{"direction":"horizontal"},on:{"change":function($event){return _vm.handleMediaStatus($event, media.mediaSequence - 1)}},model:{value:(
                _vm.$store.state.uploadMediaMonitor.mediaArray[
                  media.mediaSequence - 1
                ].mediaAbnormalValues
              ),callback:function ($$v) {_vm.$set(_vm.$store.state.uploadMediaMonitor.mediaArray[
                  media.mediaSequence - 1
                ], "mediaAbnormalValues", $$v)},expression:"\n                $store.state.uploadMediaMonitor.mediaArray[\n                  media.mediaSequence - 1\n                ].mediaAbnormalValues\n              "}},[_c('van-checkbox',{staticClass:"radio-item",attrs:{"name":"0","checked-color":"#009944"}},[_vm._v("正常")]),_c('van-checkbox',{staticClass:"radio-item",attrs:{"name":"1","checked-color":"#E55757"}},[_vm._v("未上刊")]),_c('van-checkbox',{staticClass:"radio-item",attrs:{"name":"2","checked-color":"#E55757"}},[_vm._v("亮灯异常")]),_c('van-checkbox',{staticClass:"radio-item",attrs:{"name":"3","checked-color":"#E55757"}},[_vm._v("媒体异常")]),_c('van-checkbox',{staticClass:"radio-item",attrs:{"name":"4","checked-color":"#E55757"}},[_vm._v("版本错别")]),_c('van-checkbox',{staticClass:"radio-item",attrs:{"name":"5","checked-color":"#E55757"}},[_vm._v("画面污损")]),_c('van-checkbox',{staticClass:"radio-item",attrs:{"name":"6","checked-color":"#E55757"}},[_vm._v("其他异常")])],1),(
                _vm.$store.state.uploadMediaMonitor.mediaArray[
                  media.mediaSequence - 1
                ].mediaAbnormalValues.includes('6')
              )?_c('van-field',{attrs:{"label":null,"placeholder":"请输入具体异常情况"},model:{value:(
                _vm.$store.state.uploadMediaMonitor.mediaArray[
                  media.mediaSequence - 1
                ].mediaOtherAbnormal
              ),callback:function ($$v) {_vm.$set(_vm.$store.state.uploadMediaMonitor.mediaArray[
                  media.mediaSequence - 1
                ], "mediaOtherAbnormal", $$v)},expression:"\n                $store.state.uploadMediaMonitor.mediaArray[\n                  media.mediaSequence - 1\n                ].mediaOtherAbnormal\n              "}}):_vm._e()],1)])],2)}),0),(_vm.valid == 1 || (_vm.valid == 2 && _vm.jobStatus == 5))?_c('div',{staticClass:"operate-wrapper"},[(_vm.isCeche && (_vm.jobStatus < 0 || (_vm.jobStatus > 2 && _vm.jobStatus < 6)))?_c('div',{class:['btn indexed-btn', _vm.isUploadDisable ? 'disable' : ''],on:{"click":function($event){$event.stopPropagation();return _vm.handleCache($event)}}},[_vm._v(" 本地缓存 ")]):_vm._e(),(_vm.jobStatus <= 12)?_c('div',{class:['btn next-btn', _vm.isUploadDisable ? 'disable' : ''],on:{"click":function($event){$event.stopPropagation();return _vm.handleSubmit($event)}}},[_vm._v(" 立即上报 ")]):_vm._e(),_c('div',{staticClass:"btn pre-btn",on:{"click":function($event){$event.stopPropagation();return _vm.handlePreStep($event)}}},[_vm._v("返回")])]):_vm._e()],1)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"header"},[_c('div',{staticClass:"circle"}),_vm._v(" 监测要求 ")])}]

export { render, staticRenderFns }