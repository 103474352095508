<template>
    <div>
        <div class="upload-container">
            <loading :active.sync="isLoading" :is-full-page="false"></loading>
            <!-- 上传组件 -->
            <div v-if="!isDone" :class="[
          'upload-box',
          $store.state.isPositionFail ? 'location-fail' : '',
        ]">
                <span :class="[
            'iconfont camera-icon',
            $store.state.isPositionFail ? 'icondingweishibai' : 'iconcamera',
          ]"></span>
                <span class="tips">{{
                    $store.state.isPositionFail ? "定位失败" : "视频上传"
                    }}</span>
                <input v-if="isAsyncFile" class="select-videofile" @change="handleSelectFile" type="file" accept="video/*" />
                <input v-else class="select-videofile" @change="handleSelectFile" type="file" accept="video/*" capture="camcorder" />
            </div>
            <!-- 视频展示 -->
            <div v-else class="file-show">
                <span class="iconfont iconclose close-icon" v-show="!isUploading && fileStatus <= 0 && isOperate" @click.stop="handleDel"></span>
                <div class="video-container" :id="`videoPlayer${mediaIndex}-${fileIndex}`"></div>
            </div>
        </div>
        <!-- progress -->
        <div v-show="isUploading" class="progress-wrapper">
            <p class="progress">
                <span v-show="uploadStatus > 0" :class="[
            'iconfont',
            uploadStatus == 1 ? 'iconuploadsucc' : 'iconuploadfail',
          ]"></span>
                {{
                uploadStatus == 1
                ? "视频上传成功"
                : uploadStatus == 2
                ? uploadFailTips
                : `已上传: ${(uploadProgress * 100).toFixed(1)}%`
                }}
            </p>
        </div>
    </div>
</template>
<script>
import Vue from "vue";
import { Toast, Dialog } from "vant";
Vue.use(Toast);
Vue.use(Dialog);
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import Player from "xgplayer/dist/core_player";
import { play, fullscreen, flex } from "xgplayer/dist/controls";
export default {
    name: "VideoUploader",
    data() {
        return {
            fileStatus: -1, //文件审核状态
            videoUploaded: false, //当前视频是否已经上传过了
            isDone: false,
            nowDate: null,
            uploadFileUrl: null, //上传的视频
            fileId: undefined,
            fileSize: 0,
            client: "PC", //终端默认PC
            videoType: "video/mp4",
            isLoading: false,
            fileBlobObj: null,
            isUploading: false, //是否正在上传
            uploadStatus: 0, // 0 - 没有上传 1 - 上传成功 2 - 上传失败
            uploadFailTips: "上传失败",
            uploadProgress: 0,
        };
    },
    props: [
        "isOperate",
        "fileDetail",
        "db",
        "index",
        "isAsyncFile",
        "fileSequence",
        "taskId",
        "projectId",
        "mediaIndex",
        "fileIndex",
        "token",
    ],
    watch: {
        db: {
            handler(newDB, oldDB) {
                this.getDataFromIDB();
            },
            immediate: true,
        },
    },
    components: {
        Loading,
    },
    methods: {
        guid() {
            return "xxxxx-x".replace(/[xy]/g, function(c) {
                var r = (Math.random() * 6) | 0,
                    v = c == "x" ? r : (r & 0x3) | 0x8;
                return v.toString(6);
            });
        },
        //视频选择
        handleSelectFile(e) {
            const that = this;

            this.isLoading = true;

            //获取Oss配置参数
            this.getOssSTSInfo();

            const file = e.target.files[0];
            //文件类型 MIME类型
            const fileType = file.type;

            this.videoType = file.type;

            const fileReader = new FileReader();

            // 文件总大小
            this.fileSize = file.size;

            //获取系统时间
            this.$axios
                .get("https://monitorapi.greatchinaoutdoor.com/system/time")
                .then((res) => {
                    this.nowDate = res.data.data.time;
                    readFile();
                });

            //分片读取视频
            function readFile() {
                const newBlob = new Blob([file], { type: fileType });

                let fileURL = window.URL.createObjectURL(newBlob);
                that.uploadFileUrl = fileURL;
                that.isLoading = false;
                that.isDone = true;
                that.$previewRefresh();

                that.$nextTick(() => {
                    //实例播放
                    let player = new Player({
                        id: `videoPlayer${that.mediaIndex}-${that.fileIndex}`,
                        height: "100%",
                        url: fileURL,
                        controlPlugins: [play, fullscreen, flex],
                    });
                });

                //将blob写入数据库
                that.addBlobIDB(newBlob);
            }
        },
        //将blob写入数据库
        addBlobIDB(fileBlob) {
            const that = this;
            let objectStore = this.db
                .transaction(["files_os"], "readwrite")
                .objectStore("files_os");
            this.fileId = this.index;

            let record = {
                id: this.fileId,
                taskId: this.taskId,
                lng: localStorage.getItem("lng"),
                lat: localStorage.getItem("lat"),
                captureTime: that.nowDate,
                type: "video",
                orientationValue: 2,
                fileBlob: fileBlob,
            };
            // Add the record to the IDB using add()
            let request = objectStore.add(record);
            request.onsuccess = function() {
                console.log("数据写入成功!");
                that.fileBlobObj = record;
            };
            request.onerror = function(event) {
                Toast("数据写入失败！");
            };
        },
        //更新数据
        uploadBlobIDB(data) {
            const that = this;
            var request = this.db
                .transaction(["files_os"], "readwrite")
                .objectStore("files_os")
                .put(data);

            request.onsuccess = function(event) {
                console.log("数据更新成功");
            };
        },
        //从数据库中读取数据并展示
        getDataFromIDB() {
            const that = this;
            this.fileId = this.index;
            if (this.db != null) {
                if (this.fileDetail) {
                    this.fileStatus = this.fileDetail.fileAuditStatusValue;
                    //证明已经上传过视频，只需展示即可
                    this.videoUploaded = true;
                    this.isDone = true;

                    const playerId = `videoPlayer${this.mediaIndex}-${this.fileIndex}`;
                    const playerUrl = this.fileDetail.fileUrl;
                    //视频播放器初始化
                    this.$nextTick(() => {
                        this.initPlayer(playerId, playerUrl);
                    });

                    //将获取到的线上数据存储到数据库 --start -(用于缓存) ==================
                    let objectStore = this.db
                        .transaction(["files_os"], "readwrite")
                        .objectStore("files_os");
                    this.fileId = this.index;

                    let record = {
                        id: this.fileId,
                        taskId: this.taskId,
                        lng: this.fileDetail.captureLng,
                        lat: this.fileDetail.captureLat,
                        captureTime: this.fileDetail.captureTime,
                        type: "video",
                        orientationValue: 2,
                        successUrl: this.fileDetail.fileUrl,
                    };
                    // Add the record to the IDB using add()
                    let request = objectStore.add(record);
                    request.onsuccess = function() {
                        // console.log("数据写入成功!");
                        that.fileBlobObj = record;
                    };
                    //将获取到的线上数据存储到数据库 --end -(用于缓存) =============
                    let fileObj = {
                        fileSequence: this.fileSequence,
                        fileTypeValue: 2,
                        fileUrl: this.fileDetail.fileUrl,
                        captureTime: this.fileDetail.captureTime,
                        captureLng: this.fileDetail.captureLng,
                        captureLat: this.fileDetail.captureLat,
                        orientationValue: 2,
                    };

                    let monitorData = this.$store.state.uploadMediaMonitor;
                    monitorData.mediaArray[this.mediaIndex - 1].fileArray[
                        this.fileSequence - 1
                    ] = fileObj;
                    this.$store.commit("setUploadMonitorData", monitorData);
                } else {
                    //视频之前没有上传过
                    this.videoUploaded = false;
                    //获取oss相关配置
                    this.getOssSTSInfo();

                    console.log("开始根据主键读取数据");
                    //展示写入的数据
                    var objectStore = this.db
                        .transaction("files_os")
                        .objectStore("files_os");
                    var request = objectStore.get(this.index);
                    request.onerror = function(event) {
                        console.log("事务失败");
                    };
                    request.onsuccess = function(event) {
                        if (request.result) {
                            that.fileBlobObj = request.result;
                            let fileURL = null;
                            if (request.result.successUrl) {
                                //之前上传成功过，直接显示
                                fileURL = request.result.successUrl;
                            } else {
                                //获取视频blob
                                const fileBlob = request.result.fileBlob;
                                that.videoType = request.result.fileBlob.type;
                                //展示视频
                                fileURL = URL.createObjectURL(fileBlob);
                            }

                            that.uploadFileUrl = fileURL;
                            that.isDone = true;

                            that.$nextTick(() => {
                                //实例播放
                                let player = new Player({
                                    id: `videoPlayer${that.mediaIndex}-${that.fileIndex}`,
                                    height: "100%",
                                    url: fileURL,
                                    controlPlugins: [play, fullscreen, flex],
                                });
                            });
                        } else {
                            console.log("未检索到相关数据");
                        }
                    };
                }
            }
        },
        //从数据库中删除数据
        handleDel() {
            Dialog.confirm({
                    title: "删除",
                    message: "您确定要删除这条数据吗？",
                })
                .then(() => {
                    if (this.videoUploaded) {
                        //如果上传过在进行删除，需要从oss服务器上删除对应文件
                        this.deleteOssFile();
                    } else {
                        //将视频置于未上传
                        this.videoUploaded = false;
                        this.fileStatus = -1; //重置
                        const that = this;
                        var request = this.db
                            .transaction(["files_os"], "readwrite")
                            .objectStore("files_os")
                            .delete(this.fileId);

                        request.onsuccess = function(event) {
                            that.uploadFileUrl = null;
                            that.isDone = false; //返回重新拍摄
                            that.videoType = "video/mp4";
                            console.log("数据删除成功");
                        };
                    }
                })
                .catch(() => {
                    // on cancel
                });
        },
        //删除oss对应文件
        deleteOssFile() {
            const that = this;
            //只有待审核的文件才允许删除oss对应文件
            if (this.fileStatus == 0) {
                this.$axios
                    .post(
                        "https://monitorapi.greatchinaoutdoor.com/job/ossDel", {
                            id: this.taskId,
                            fileId: this.fileDetail.id,
                        }, {
                            headers: {
                                user_token: this.token,
                            },
                        }
                    )
                    .then((res) => {
                        if (res.data.success == "true") {
                            var request = this.db
                                .transaction(["files_os"], "readwrite")
                                .objectStore("files_os")
                                .delete(this.fileId);

                            request.onsuccess = function(event) {
                                that.fileStatus = -1; //重置
                                that.uploadFileUrl = null;
                                that.isDone = false; //返回重新拍摄
                                that.fileBlobObj = null;
                                console.log("数据删除成功");
                                //将视频置于未上传
                                that.videoUploaded = false;
                                //重新获取oss配置
                                // that.getOssSTSInfo();
                            };
                        } else {
                            Toast(res.data.data.msg);
                        }
                    });
            } else {
                //只删除本地数据库文件
                var request = this.db
                    .transaction(["files_os"], "readwrite")
                    .objectStore("files_os")
                    .delete(this.fileId);

                request.onsuccess = function(event) {
                    that.fileStatus = -1; //重置
                    that.uploadFileUrl = null;
                    that.isDone = false; //返回重新拍摄
                    that.fileBlobObj = null;
                    console.log("数据删除成功");
                    //将视频置于未上传
                    that.videoUploaded = false;
                    //重新获取oss配置
                    // that.getOssSTSInfo();
                };
            }
        },
        //获取当前系统时间
        getNowDate() {
            this.$axios
                .get("https://monitorapi.greatchinaoutdoor.com/system/time")
                .then((res) => {
                    this.nowDate = res.data.data.time;
                });
        },
        //获取当前终端
        judgeClient() {
            let u = navigator.userAgent;
            let isAndroid = u.indexOf("Android") > -1 || u.indexOf("Adr") > -1;
            let isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
            if (isAndroid) {
                this.client = "Android";
            } else if (isIOS) {
                this.client = "IOS";
            } else {
                this.client = "PC";
            }
        },
        //oss上传视频
        fileUpload() {
            const that = this;
            if (!this.videoUploaded) {
                const ossSign = this.ossSign;
                if (ossSign == null) return;
                //OSS直传blob
                let clientOss = new OSS({
                    region: ossSign.region,
                    accessKeyId: ossSign.accessKeyId,
                    accessKeySecret: ossSign.accessKeySecret,
                    stsToken: ossSign.securityToken,
                    bucket: ossSign.bucket,
                });

                if (this.fileBlobObj != null) {
                    this.isUploading = true;

                    let mineType = this.videoType == "video/mp4" ? "mp4" : "mov";
                    //dir -- 设置方式   项目ID / 任务ID / 随机数.mov
                    const fileName = `${ossSign.dir}/${this.projectId}/${
            this.taskId
          }/${new Date().getTime()}${this.guid()}.${mineType}`;
                    //上传
                    clientOss
                        .multipartUpload(fileName, that.fileBlobObj.fileBlob, {
                            timeout: 10000,
                            parallel: 4,
                            partSize: 1000 * 1024, //设置分片大小
                            progress: function(p, checkpoint) {
                                console.log(p);
                                //设置上传进度
                                that.uploadProgress = p;
                            },
                            mime: that.videoType,
                            callback: {
                                url: ossSign.callBackUrl,
                                body: `bucket=${ossSign.bucket}&objectName=${fileName}&jobId=${that.taskId}&size=${that.fileSize}`,
                            },
                        })
                        .then(function(res) {
                            console.log(res);
                            if (res.data.success == "true") {
                                let fileIDB = that.fileBlobObj;
                                fileIDB.successUrl = res.res.requestUrls[0];
                                //更新数据库
                                that.uploadBlobIDB(fileIDB);

                                that.uploadStatus = 1;
                                let uploadSuccessCount = that.$store.state.uploadSuccessCount;
                                uploadSuccessCount++;
                                that.$store.commit("setUploadSuccessCount", uploadSuccessCount);

                                let fileObj = {
                                    fileSequence: that.fileSequence,
                                    fileTypeValue: 2,
                                    fileUrl: res.res.requestUrls[0],
                                    captureTime: that.fileBlobObj.captureTime,
                                    captureLng: that.fileBlobObj.lng,
                                    captureLat: that.fileBlobObj.lat,
                                    orientationValue: 2,
                                };

                                let monitorData = that.$store.state.uploadMediaMonitor;
                                monitorData.mediaArray[that.mediaIndex - 1].fileArray[
                                    that.fileSequence - 1
                                ] = fileObj;
                                console.log(monitorData);
                                that.$store.commit("setUploadMonitorData", monitorData);
                            } else {
                                that.uploadStatus = 2;
                                that.uploadFailTips = res.data.data.msg;
                            }
                        })
                        .catch(function(err) {
                            console.error("error: %j", err);
                            that.uploadStatus = 2;
                        });
                }
            } else {
                this.isUploading = true; //显示文件上传的提示
                //不用重新上传，直接显示上传成功即可
                this.uploadStatus = 1;
                let uploadSuccessCount = this.$store.state.uploadSuccessCount;
                uploadSuccessCount++;
                this.$store.commit("setUploadSuccessCount", uploadSuccessCount);
            }
        },
        //获取oss的相关配置信息
        getOssSTSInfo() {
            const data0 = {
                id: this.taskId,
                mediaSequence: this.mediaIndex,
                fileSequence: this.fileIndex,
                fileTypeValue: 2,
            };

            this.$axios
                .post(
                    "https://monitorapi.greatchinaoutdoor.com/job/moniOssPostPolicy",
                    data0, {
                        headers: {
                            user_token: this.token,
                        },
                    }
                )
                .then((res) => {
                    if (res.data.success == "true") {
                        this.ossSign = res.data.data.credentials;
                    } else {
                        Toast(res.data.data.msg);
                    }
                });
        },
        //初始化播放器
        initPlayer(id, playUrl) {
            //实例播放
            let player = new Player({
                id: id,
                height: "100%",
                url: playUrl,
                controlPlugins: [play, fullscreen, flex],
            });
        },
    },
    created() {
        //判断终端类型
        this.judgeClient();
    },
    mounted() {
        this.$bus.on("file-upload", this.fileUpload);
        if (this.fileDetail == null || this.fileDetail.fileAuditStatusValue < 0) {
            //获取oss相关配置信息
            this.getOssSTSInfo();
        }
    },
    beforeDestroy() {
        this.$bus.off("file-upload", this.fileUpload);
    },
};
</script>
<style lang="less" scoped>
.progress-wrapper {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 24px;
    line-height: 24px;
    background: rgba(0, 0, 0, 0.6);
    text-align: center;
    z-index: 999;

    .progress {
        font-size: 14px;
        color: #fff;
    }
}

.upload-container {
    width: 100%;
    height: 100%;
    background: #f4f4f4;
    border-radius: 8px;

    .upload-box {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        outline: none;
        position: relative;

        &.location-fail {
            pointer-events: none;
            background: #ddd;
        }

        .camera-icon {
            font-size: 40px;
            color: #ccc;
        }

        .tips {
            font-size: 14px;
            color: #bbb;
            margin-top: 4px;
        }

        .select-videofile {
            position: absolute;
            width: 100%;
            height: 100%;
            outline: none;
            opacity: 0;
        }
    }

    .file-show {
        position: relative;
        display: flex;
        align-items: center;
        overflow: hidden;
        height: 100%;

        >img {
            width: 100%;
            object-fit: fill;
        }

        .video-container {
            width: 100%;
            height: 100%;
        }

        .close-icon {
            position: absolute;
            right: 0;
            top: 0;
            font-size: 24px;
            color: #c40b0b;
            z-index: 99;
        }
    }
}
</style>