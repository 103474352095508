<template>
    <div>
        <div class="upload-container">
            <loading :active.sync="isLoading" :is-full-page="false"></loading>
            <!-- 上传组件 -->
            <div v-if="!isDone" :class="[
          'upload-box',
          $store.state.isPositionFail ? 'location-fail' : '',
        ]">
                <span :class="[
            'iconfont camera-icon',
            $store.state.isPositionFail ? 'icondingweishibai' : 'iconcamera',
          ]"></span>
                <span class="tips">{{
                    $store.state.isPositionFail ? "定位失败" : "拍照上传"
                    }}</span>
                <input v-if="isAsyncFile" class="select-imgfile" @change="handleSelectImg" type="file" accept="image/*" />
                <input v-else class="select-imgfile" @change="handleSelectImg" type="file" accept="image/*" capture="camera" />
            </div>
            <!-- 图片展示 -->
            <div v-else class="img-show">
                <span class="iconfont iconclose close-icon" @click.stop="handleDel" v-show="!isUploading && fileStatus <= 0 && isOperate"></span>
                <img preview="10" :src="uploadImgUrl" />
            </div>
        </div>
        <!-- progress -->
        <div v-show="isUploading" class="progress-wrapper">
            <p class="progress">
                <span v-show="uploadStatus > 0" :class="[
            'iconfont',
            uploadStatus == 1 ? 'iconuploadsucc' : 'iconuploadfail',
          ]"></span>
                {{ uploadTips }}
            </p>
        </div>
    </div>
</template>
<script>
import Vue from "vue";
import { Toast, Dialog } from "vant";
Vue.use(Toast);
Vue.use(Dialog);
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

//获取元素在数组的下标
Array.prototype.indexOf = function(val) {
    for (var i = 0; i < this.length; i++) {
        if (this[i] == val) {
            return i;
        }
    }
    return -1;
};

//根据数组的下标，删除该下标的元素
Array.prototype.remove = function(val) {
    var index = this.indexOf(val);
    if (index > -1) {
        this.splice(index, 1);
    }
};

export default {
    name: "ImgUploader",
    data() {
        return {
            fileStatus: -1, //文件审核状态
            imgUploaded: false, //当前图片是否已经上传过了
            ossSign: null, //oss签名配置
            isDone: false,
            direction: undefined, //拍摄方向 -- 1: 横向  2:纵向
            nowDate: null,
            uploadImgUrl: null, //上传的图片
            isLoading: false,
            fileId: undefined,
            fileBlobObj: null,
            isUploading: false, //是否正在上传
            uploadStatus: 0, // 0 - 没有上传 1 - 上传成功 2 - 上传失败
            uploadTips: "上传中...",
        };
    },
    props: [
        "isOperate",
        "fileDetail",
        "db",
        "index",
        "isAsyncFile",
        "fileSequence",
        "taskId",
        "projectId",
        "mediaIndex",
        "fileIndex",
        "token",
        "orientation",
    ],
    watch: {
        db: {
            handler(newDB, oldDB) {
                this.getDataFromIDB();
            },
            immediate: true,
        },
    },
    components: {
        Loading,
    },
    methods: {
        guid() {
            return "xxxxx-x".replace(/[xy]/g, function(c) {
                var r = (Math.random() * 6) | 0,
                    v = c == "x" ? r : (r & 0x3) | 0x8;
                return v.toString(6);
            });
        },
        //图片选择
        handleSelectImg(e) {
            const that = this;

            this.isLoading = true;

            const file = e.target.files[0];
            //获取Oss配置项
            this.getOssSTSInfo();

            //转换成img
            var img = document.createElement("img");
            img.src = window.URL.createObjectURL(file);
            img.onload = () => {
                //获取当前北京时间
                that.$axios
                    .get("https://monitorapi.greatchinaoutdoor.com/system/time")
                    .then((res) => {
                        that.nowDate = res.data.data.time;

                        //转canvas实现压缩
                        var canvas = document.createElement("canvas");
                        var ctx = canvas.getContext("2d");

                        var width = img.width;
                        var height = img.height;

                        //方向计算 -- 通过横纵比判断横排还是纵拍
                        // 1: 横向  2: 纵线
                        const directionPercent = width / height;
                        that.direction = directionPercent > 1 ? 1 : 2;
                        if (that.direction != that.orientation) {
                            that.isLoading = false;
                            Toast("拍摄的图片旋转方向与规定不相符！");
                            return;
                        }

                        canvas.width = Math.min(width, 1920);
                        const ratio = canvas.width / width;
                        canvas.height = height * ratio;
                        //绘制到canvas上
                        ctx.drawImage(img, 0, 0, Math.min(width, 1920), height * ratio);

                        //水印相关设置
                        const watermarkConfig = that.$store.state.watermarkConfig;
                        const waterContent = watermarkConfig.watermarkContent;
                        // 水印位置
                        const waterPosition = watermarkConfig.watermarkPostition;
                        //水印文字大小
                        const waterSize = watermarkConfig.watermarkSize;
                        const waterContentArray = waterContent ?
                            waterContent.split(",") :
                            [];
                        console.log(waterContentArray);
                        //是否需要设置logo
                        let hasLogo = waterContentArray.includes("1");
                        waterContentArray.remove("1");

                        //水印设置
                        //start--------------
                        let watermark;
                        ctx.font = `${waterSize}px 宋体`;
                        ctx.fillStyle = "#FFC82C";
                        if (waterPosition == 1 || waterPosition == 4) {
                            // 设置左对齐
                            ctx.textAlign = "left";
                        } else if (waterPosition == 2 || waterPosition == 3) {
                            // 设置右对齐
                            ctx.textAlign = "right";
                        }

                        waterContentArray.forEach((item, index) => {
                            if (item == 3) {
                                watermark = `拍摄时间:${that.nowDate}`;
                            } else if (item == 4) {
                                watermark = `经纬度: ${localStorage.getItem(
                  "lng"
                )}/${localStorage.getItem("lat")}`;
                            } else {
                                watermark = that.$store.state.watermarkConfig[`key${item}`];
                            }
                            that.waterTextCanvas(
                                ctx,
                                watermark,
                                index,
                                waterSize + 2,
                                canvas.width,
                                canvas.height,
                                waterPosition
                            );
                        });
                        //end

                        //如果需要添加logo
                        if (hasLogo) {
                            //添加logo水印
                            var logoImg = document.createElement("img");
                            logoImg.setAttribute("crossOrigin", "anonymous");
                            logoImg.src = watermarkConfig.key1;
                            // logoImg.src = logoPath;
                            logoImg.onload = () => {
                                console.log("网络图片加载");
                                const logoImgWidth = Math.min(logoImg.width, 120);
                                const imgPercent = logoImgWidth / logoImg.width;
                                const logoImgHeight = logoImg.height * imgPercent;

                                if (waterPosition == 1) {
                                    //logo在左上角最下面
                                    ctx.drawImage(
                                        logoImg,
                                        10,
                                        logoImgHeight + waterSize * waterContentArray.length,
                                        logoImgWidth,
                                        logoImgHeight
                                    );
                                } else if (waterPosition == 2) {
                                    //logo在右上角最下面
                                    ctx.drawImage(
                                        logoImg,
                                        canvas.width - logoImgWidth - 10,
                                        logoImgHeight + waterSize * waterContentArray.length,
                                        logoImgWidth,
                                        logoImgHeight
                                    );
                                } else if (waterPosition == 3) {
                                    ctx.drawImage(
                                        logoImg,
                                        canvas.width - logoImgWidth - 10,
                                        canvas.height -
                                        logoImgHeight -
                                        10 -
                                        waterSize * waterContentArray.length,
                                        logoImgWidth,
                                        logoImgHeight
                                    );
                                } else {
                                    ctx.drawImage(
                                        logoImg,
                                        10,
                                        canvas.height -
                                        logoImgHeight -
                                        10 -
                                        waterSize * waterContentArray.length,
                                        logoImgWidth,
                                        logoImgHeight
                                    );
                                }

                                //canvas转blob
                                canvas.toBlob(
                                    function(blob) {
                                        //获取blob
                                        const fileBlob = blob;
                                        console.log("读取文件blob成功！");

                                        //展示图片
                                        let fileURL = URL.createObjectURL(fileBlob);
                                        that.uploadImgUrl = fileURL;
                                        that.isLoading = false;
                                        that.isDone = true;
                                        that.$previewRefresh();

                                        //将blob写入数据库
                                        that.addBlobIDB(fileBlob);
                                    },
                                    "image/jpeg",
                                    0.9
                                );
                            };
                        } else {
                            //canvas转blob
                            canvas.toBlob(
                                function(blob) {
                                    //获取blob
                                    const fileBlob = blob;
                                    console.log("读取文件blob成功！");

                                    //展示图片
                                    let fileURL = URL.createObjectURL(fileBlob);
                                    that.uploadImgUrl = fileURL;
                                    that.isLoading = false;
                                    that.isDone = true;
                                    that.$previewRefresh();

                                    //将blob写入数据库
                                    that.addBlobIDB(fileBlob);
                                },
                                "image/jpeg",
                                0.9
                            );
                        }
                    });
            };
        },
        //生成字体水印
        waterTextCanvas(ctx, text, index, fontSize, width, height, position) {
            if (position == 1) {
                //左上角
                console.log(10 + fontSize * index);
                ctx.fillText(text, 10, fontSize * (index + 1));
            } else if (position == 2) {
                ctx.fillText(text, width - 10, fontSize * (index + 1));
            } else if (position == 3) {
                //右下角
                ctx.fillText(text, width - 10, height - 10 - fontSize * index);
            } else {
                //左下角
                ctx.fillText(text, 10, height - 10 - fontSize * index);
            }
        },
        //将blob写入数据库
        addBlobIDB(fileBlob) {
            const that = this;
            let objectStore = this.db
                .transaction(["files_os"], "readwrite")
                .objectStore("files_os");
            this.fileId = this.index;
            let record = {
                id: this.fileId,
                taskId: this.taskId,
                lng: localStorage.getItem("lng"),
                lat: localStorage.getItem("lat"),
                captureTime: that.nowDate,
                type: "image",
                orientationValue: that.direction,
                fileBlob: fileBlob,
            };
            // Add the record to the IDB using add()
            let request = objectStore.add(record);
            request.onsuccess = function() {
                // console.log("数据写入成功!");
                that.fileBlobObj = record;
            };
            request.onerror = function(event) {
                Toast("数据写入失败！");
            };
        },
        //更新数据
        uploadBlobIDB(data) {
            const that = this;
            var request = this.db
                .transaction(["files_os"], "readwrite")
                .objectStore("files_os")
                .put(data);

            request.onsuccess = function(event) {
                console.log("数据更新成功");
            };
        },
        //从数据库中删除数据
        handleDel() {
            const that = this;
            Dialog.confirm({
                    title: "删除",
                    message: "您确定要删除这条数据吗？",
                })
                .then(() => {
                    if (this.imgUploaded) {
                        //如果上传过在进行删除，需要从oss服务器上删除对应文件
                        this.deleteOssFile();
                    } else {
                        //将图片置于未上传
                        this.imgUploaded = false;
                        this.fileStatus = -1; //重置
                        var request = this.db
                            .transaction(["files_os"], "readwrite")
                            .objectStore("files_os")
                            .delete(this.fileId);

                        request.onsuccess = function(event) {
                            that.uploadImgUrl = null;
                            that.isDone = false; //返回重新拍摄
                            that.fileBlobObj = null;
                            console.log("数据删除成功");
                        };
                    }
                })
                .catch(() => {
                    // on cancel
                });
        },
        //删除oss对应文件
        deleteOssFile() {
            const that = this;
            //只有待审核的文件才允许删除oss对应文件
            if (this.fileStatus == 0) {
                this.$axios
                    .post(
                        "https://monitorapi.greatchinaoutdoor.com/job/ossDel", {
                            id: this.taskId,
                            fileId: this.fileDetail.id,
                        }, {
                            headers: {
                                user_token: this.token,
                            },
                        }
                    )
                    .then((res) => {
                        if (res.data.success == "true") {
                            var request = this.db
                                .transaction(["files_os"], "readwrite")
                                .objectStore("files_os")
                                .delete(this.fileId);

                            request.onsuccess = function(event) {
                                that.fileStatus = -1; //重置
                                that.uploadImgUrl = null;
                                that.isDone = false; //返回重新拍摄
                                that.fileBlobObj = null;
                                console.log("数据删除成功");
                                //将图片置于未上传
                                that.imgUploaded = false;
                                //重新获取oss配置
                                // this.getOssSTSInfo();
                            };
                        } else {
                            Toast(res.data.data.msg);
                        }
                    });
            } else {
                //只删除本地数据库文件
                var request = this.db
                    .transaction(["files_os"], "readwrite")
                    .objectStore("files_os")
                    .delete(this.fileId);

                request.onsuccess = function(event) {
                    that.fileStatus = -1; //重置
                    that.uploadImgUrl = null;
                    that.isDone = false; //返回重新拍摄
                    that.fileBlobObj = null;
                    console.log("数据删除成功");
                    //将图片置于未上传
                    that.imgUploaded = false;
                    //重新获取oss配置
                    // this.getOssSTSInfo();
                };
            }
        },
        //从数据库中读取数据并展示
        getDataFromIDB() {
            const that = this;
            this.fileId = this.index;
            if (this.db != null) {
                if (this.fileDetail) {
                    this.fileStatus = this.fileDetail.fileAuditStatusValue;
                    //证明已经上传过图片，只需展示即可
                    this.imgUploaded = true;
                    this.isDone = true;
                    this.uploadImgUrl = this.fileDetail.fileUrl;

                    //将获取到的线上数据存储到数据库 --start -(用于缓存) ==================
                    const that = this;
                    let objectStore = this.db
                        .transaction(["files_os"], "readwrite")
                        .objectStore("files_os");
                    this.fileId = this.index;

                    let record = {
                        id: this.fileId,
                        taskId: this.taskId,
                        lng: this.fileDetail.captureLng,
                        lat: this.fileDetail.captureLat,
                        captureTime: this.fileDetail.captureTime,
                        type: "image",
                        orientationValue: this.fileDetail.orientationValue,
                        successUrl: this.fileDetail.fileUrl,
                    };
                    // Add the record to the IDB using add()
                    let request = objectStore.add(record);
                    request.onsuccess = function() {
                        // console.log("数据写入成功!");
                        that.fileBlobObj = record;
                    };
                    //将获取到的线上数据存储到数据库 --end -(用于缓存) =============

                    let fileObj = {
                        fileSequence: this.fileSequence,
                        fileTypeValue: 1,
                        fileUrl: this.fileDetail.fileUrl,
                        captureTime: this.fileDetail.captureTime,
                        captureLng: this.fileDetail.captureLng,
                        captureLat: this.fileDetail.captureLat,
                        orientationValue: this.fileDetail.orientationValue,
                    };
                    let monitorData = this.$store.state.uploadMediaMonitor;
                    monitorData.mediaArray[this.mediaIndex - 1].fileArray[
                        this.fileSequence - 1
                    ] = fileObj;
                    this.$store.commit("setUploadMonitorData", monitorData);
                } else {
                    this.imgUploaded = false;
                    //获取oss配置参数
                    // this.getOssSTSInfo();
                    //在indexdb数据库读取
                    var objectStore = this.db
                        .transaction("files_os")
                        .objectStore("files_os");
                    var request = objectStore.get(this.index);
                    request.onerror = function(event) {
                        console.log("事务失败");
                    };
                    request.onsuccess = function(event) {
                        if (request.result) {
                            that.fileBlobObj = request.result;
                            if (request.result.successUrl) {
                                //之前上传成功过，直接显示
                                that.uploadImgUrl = request.result.successUrl;
                                that.isDone = true;
                            } else {
                                //获取图片blob
                                const fileBlob = request.result.fileBlob;
                                //展示图片
                                let fileURL = URL.createObjectURL(fileBlob);
                                that.uploadImgUrl = fileURL;
                                that.isDone = true;
                            }

                            that.$previewRefresh();
                        } else {
                            console.log("未检索到相关数据");
                        }
                    };
                }
            }
        },
        //获取当前标准的北京时间
        getNowDate() {
            this.$axios.get("https://monitorapi.greatchinaoutdoor.com/system/time").then((res) => {
                this.nowDate = res.data.data.time;
            });
        },
        //oss上传图片
        fileUpload() {
            console.log("触发了上传");
            const that = this;
            if (!this.imgUploaded) {
                const ossSign = this.ossSign;
                if (ossSign == null) return;
                //OSS直传blob
                let clientOss = new OSS({
                    region: ossSign.region,
                    accessKeyId: ossSign.accessKeyId,
                    accessKeySecret: ossSign.accessKeySecret,
                    stsToken: ossSign.securityToken,
                    bucket: ossSign.bucket,
                });

                if (this.fileBlobObj != null) {
                    this.isUploading = true; //显示文件上传的提示

                    //dir -- 设置方式   项目ID / 任务ID / 随机数.jpeg
                    const fileName = `${ossSign.dir}/${this.projectId}/${
            this.taskId
          }/${new Date().getTime()}${this.guid()}.jpeg`;
                    //上传
                    console.log(fileName);
                    clientOss
                        .put(fileName, this.fileBlobObj.fileBlob)
                        .then(function(res) {
                            console.log("put success: %j", res);

                            let fileIDB = that.fileBlobObj;
                            fileIDB.successUrl = res.url;
                            console.log(res.url);
                            //更新数据库
                            that.uploadBlobIDB(fileIDB);

                            // 上传成功提示
                            that.uploadStatus = 1;
                            that.uploadTips = "图片上传成功";
                            let uploadSuccessCount = that.$store.state.uploadSuccessCount;
                            uploadSuccessCount++;
                            that.$store.commit("setUploadSuccessCount", uploadSuccessCount);

                            let fileObj = {
                                fileSequence: that.fileSequence,
                                fileTypeValue: 1,
                                fileUrl: res.url,
                                captureTime: that.fileBlobObj.captureTime,
                                captureLng: that.fileBlobObj.lng,
                                captureLat: that.fileBlobObj.lat,
                                orientationValue: that.fileBlobObj.orientationValue,
                            };

                            let monitorData = that.$store.state.uploadMediaMonitor;
                            monitorData.mediaArray[that.mediaIndex - 1].fileArray[
                                that.fileSequence - 1
                            ] = fileObj;
                            console.log(monitorData);
                            that.$store.commit("setUploadMonitorData", monitorData);
                        })
                        .catch(function(err) {
                            console.error("error: %j", err);
                            // 上传失败提示
                            that.uploadStatus = 2;
                            that.uploadTips = "上传失败";
                        });
                }
            } else {
                this.isUploading = true; //显示文件上传的提示
                //不用重新上传，直接显示上传成功即可
                this.uploadStatus = 1;
                this.uploadTips = "图片上传成功";
                let uploadSuccessCount = this.$store.state.uploadSuccessCount;
                uploadSuccessCount++;
                this.$store.commit("setUploadSuccessCount", uploadSuccessCount);
            }
        },
        //获取oss的相关配置信息
        getOssSTSInfo() {
            const data0 = {
                id: this.taskId,
                mediaSequence: this.mediaIndex,
                fileSequence: this.fileIndex,
                fileTypeValue: 1,
            };

            this.$axios
                .post("https://monitorapi.greatchinaoutdoor.com/job/moniOssPostPolicy", data0, {
                    headers: {
                        user_token: this.token,
                    },
                })
                .then((res) => {
                    if (res.data.success == "true") {
                        this.ossSign = res.data.data.credentials;
                    } else {
                        Toast(res.data.data.msg);
                    }
                });
        },
    },
    mounted() {
        this.$bus.on("file-upload", this.fileUpload);
        if (this.fileDetail == null || this.fileDetail.fileAuditStatusValue < 0) {
            //获取oss相关配置信息
            this.getOssSTSInfo();
        }
    },
    beforeDestroy() {
        this.$bus.off("file-upload", this.fileUpload);
    },
};
</script>
<style lang="less" scoped>
.progress-wrapper {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 30px;
    line-height: 30px;
    text-align: center;
    background: rgba(0, 0, 0, 0.6);

    .progress {
        font-size: 14px;
        color: #fff;
    }
}

.upload-container {
    width: 100%;
    height: 100%;
    background: #f4f4f4;
    border-radius: 8px;

    .upload-box {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        outline: none;
        position: relative;

        &.location-fail {
            pointer-events: none;
            background: #ddd;
        }

        .camera-icon {
            font-size: 40px;
            color: #ccc;
        }

        .tips {
            font-size: 14px;
            color: #bbb;
            margin-top: 4px;
        }

        .select-imgfile {
            position: absolute;
            width: 100%;
            height: 100%;
            outline: none;
            opacity: 0;
        }
    }

    .img-show {
        height: 100%;
        position: relative;
        display: flex;
        align-items: center;
        overflow: hidden;

        >img {
            width: 100%;
            object-fit: cover;
        }

        .close-icon {
            position: absolute;
            right: 0;
            top: 0;
            font-size: 24px;
            color: #c40b0b;
        }
    }
}
</style>