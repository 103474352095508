<template>
  <div v-title data-title="现场作业">
    <div v-if="requirement" class="require-wrapper">
      <div class="header">
        <div class="circle"></div>
        监测要求
      </div>
      <div class="require-content">{{ requirement }}</div>
    </div>
    <div class="main-wrapper">
      <!-- map -->
      <AMap
        :isLocation="isLocation"
        :locationThreshold="locationThreshold"
        :jobStatus="jobStatus"
        :lat="lat"
        :lng="lng"
      ></AMap>
      <div>
        <!-- card -->
        <div
          class="task-card__wrapper"
          v-for="(media, idx) in monitorList"
          :key="idx"
        >
          <h2 class="media-title">媒体{{ idx + 1 }}</h2>
          <div v-for="(item, index) in media.fileStanArr" :key="index">
            <div
              :class="[
                'item',
                'mb-md',
                item.file && item.file.fileAuditStatusValue < 0
                  ? 'warning'
                  : '',
              ]"
              v-if="item.captureStandardModel.fileTypeValue == 1"
            >
              <h4>
                <div class="circle"></div>
                {{ item.captureStandardModel.exampleName }}
              </h4>
              <div class="file-wrapper">
                <!-- <div
                  :class="[
                    'image-box',
                    'mr-md',
                    item.captureStandardModel.orientationValue == 1
                      ? ''
                      : 'vertical',
                  ]"
                >
                  <img
                    preview="0"
                    :src="item.captureStandardModel.exampleOssUrl"
                  />
                  <p class="tips">示例图片</p>
                </div> -->
                <img-uploader
                  :class="[
                    'image-box',
                    item.captureStandardModel.orientationValue == 1
                      ? ''
                      : 'vertical',
                  ]"
                  :isOperate="valid == 1 || (valid == 2 && jobStatus == 5)"
                  :fileDetail="item.file"
                  :isAsyncFile="isAsyncFile"
                  :db="db"
                  :orientation="item.captureStandardModel.orientationValue"
                  :projectId="projectId"
                  :taskId="taskId"
                  :mediaIndex="media.mediaSequence"
                  :fileIndex="index + 1"
                  :token="token"
                  :fileSequence="index + 1"
                  :index="`${taskId}-${media.mediaSequence}-${index + 1}`"
                ></img-uploader>
              </div>
              <p
                class="warning-desc"
                v-if="item.file && item.file.fileAuditStatusValue < 0"
              >
                审核意见:{{ item.file.fileAuditStatusMeans }}
              </p>
              <p
                v-if="
                  item.file &&
                  item.file.fileAuditStatusValue < 0 &&
                  item.file.obj.auditOpinion != ''
                "
                class="warning-desc"
              >
                审核意见:{{ item.file.obj.auditOpinion }}
              </p>
              <p class="ad-img_tips">
                （{{ item.captureStandardModel.remark }}）
              </p>
            </div>
            <!-- 视频 -->
            <div
              :class="[
                'item',
                'mb-md',
                item.file && item.file.fileAuditStatusValue < 0
                  ? 'warning'
                  : '',
              ]"
              v-else
            >
              <h4>
                <div class="circle"></div>
                {{ item.captureStandardModel.exampleName }}
              </h4>
              <div class="file-wrapper">
                <!-- <div class="image-box mr-md">
                  <div
                    class="video-wrapper"
                    :id="`player${media.mediaSequence}${index + 1}`"
                  ></div>
                  <p class="tips">示例视频</p>
                </div> -->
                <video-uploader
                  :isOperate="valid == 1 || (valid == 2 && jobStatus == 5)"
                  :fileDetail="item.file"
                  class="image-box"
                  :isAsyncFile="isAsyncFile"
                  :db="db"
                  :projectId="projectId"
                  :taskId="taskId"
                  :mediaIndex="media.mediaSequence"
                  :fileIndex="index + 1"
                  :token="token"
                  :fileSequence="index + 1"
                  :index="`${taskId}-${media.mediaSequence}-${index + 1}`"
                ></video-uploader>
              </div>
              <p
                class="warning-desc"
                v-if="item.file && item.file.fileAuditStatusValue < 0"
              >
                审核结论:{{ item.file.fileAuditStatusMeans }}
              </p>
              <p
                v-if="
                  item.file &&
                  item.file.fileAuditStatusValue < 0 &&
                  item.file.obj.auditOpinion != ''
                "
                class="warning-desc"
              >
                审核意见:{{ item.file.obj.auditOpinion }}
              </p>
              <p class="ad-img_tips">
                （{{ item.captureStandardModel.remark }}）
              </p>
            </div>
          </div>
          <div class="item mb-md">
            <h4>媒体情况</h4>
            <div :class="isUploadDisable ? 'filter-disabled' : ''">
              <van-checkbox-group
                class="media-status--wrapper"
                v-model="
                  $store.state.uploadMediaMonitor.mediaArray[
                    media.mediaSequence - 1
                  ].mediaAbnormalValues
                "
                direction="horizontal"
                @change="handleMediaStatus($event, media.mediaSequence - 1)"
              >
                <van-checkbox
                  class="radio-item"
                  name="0"
                  checked-color="#009944"
                  >正常</van-checkbox
                >
                <van-checkbox
                  class="radio-item"
                  name="1"
                  checked-color="#E55757"
                  >未上刊</van-checkbox
                >
                <van-checkbox
                  class="radio-item"
                  name="2"
                  checked-color="#E55757"
                  >亮灯异常</van-checkbox
                >
                <van-checkbox
                  class="radio-item"
                  name="3"
                  checked-color="#E55757"
                  >媒体异常</van-checkbox
                >
                <van-checkbox
                  class="radio-item"
                  name="4"
                  checked-color="#E55757"
                  >版本错别</van-checkbox
                >
                <van-checkbox
                  class="radio-item"
                  name="5"
                  checked-color="#E55757"
                  >画面污损</van-checkbox
                >
                <van-checkbox
                  class="radio-item"
                  name="6"
                  checked-color="#E55757"
                  >其他异常</van-checkbox
                >
              </van-checkbox-group>
              <van-field
                v-if="
                  $store.state.uploadMediaMonitor.mediaArray[
                    media.mediaSequence - 1
                  ].mediaAbnormalValues.includes('6')
                "
                v-model="
                  $store.state.uploadMediaMonitor.mediaArray[
                    media.mediaSequence - 1
                  ].mediaOtherAbnormal
                "
                :label="null"
                placeholder="请输入具体异常情况"
              />
            </div>
          </div>
        </div>
      </div>
      <!-- 操作按钮区域 -->
      <div
        class="operate-wrapper"
        v-if="valid == 1 || (valid == 2 && jobStatus == 5)"
      >
        <div
          :class="['btn indexed-btn', isUploadDisable ? 'disable' : '']"
          v-if="isCeche && (jobStatus < 0 || (jobStatus > 2 && jobStatus < 6))"
          @click.stop="handleCache"
        >
          本地缓存
        </div>
        <div
          :class="['btn next-btn', isUploadDisable ? 'disable' : '']"
          v-if="jobStatus <= 12"
          @click.stop="handleSubmit"
        >
          立即上报
        </div>
        <div class="btn pre-btn" @click.stop="handlePreStep">返回</div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import { Toast, Checkbox, CheckboxGroup, Field } from "vant";
import AMap from "@/components/Map.vue";
import ImgUploader from "@/components/ImgUploader.vue";
import VideoUploader from "@/components/VideoUploader.vue";
import Player from "xgplayer/dist/core_player";
import { play, fullscreen, flex } from "xgplayer/dist/controls";
Vue.use(Toast);
Vue.use(Checkbox);
Vue.use(CheckboxGroup);
Vue.use(Field);
export default {
  name: "Home",
  components: {
    AMap,
    ImgUploader,
    VideoUploader,
  },
  data() {
    return {
      requirement: undefined, //监测要求
      valid: 1, //1 、可以上报 2、 不可以上报（过期）
      locationThreshold: 0,
      lat: "",
      lng: "",
      jobStatus: -1,
      uploadTotal: 0, //需要上传的文件总数
      db: null,
      projectId: "", //项目id
      taskId: "", //任务Id
      token: null,
      isCeche: false,
      isLocation: false, //是否需要位置校验
      isAsyncFile: false, //是否支持选择本地文件
      monitorList: [],
      mediaCount: 0,
      isUploadDisable: false,
      isCheckUploadBtn: false,
      standardList: [],
    };
  },
  watch: {
    "$store.state.uploadSuccessCount": function (newCount, oldCount) {
      if (newCount == this.uploadTotal) {
        //上传数据
        let monitorData = this.$store.state.uploadMediaMonitor;
        monitorData.postLng = localStorage.getItem("lng");
        monitorData.postLat = localStorage.getItem("lat");
        monitorData.remark = undefined;
        //上报数据
        this.uploadMonitorData(monitorData);
      }
    },
  },
  methods: {
    //操作按钮，点击返回上一步
    handlePreStep() {
      if (this.jobStatus != 5) {
        //返回之前的页面也需要将相关数据清空
        this.clearDBData(this.taskId);
      }
      wx.miniProgram.navigateBack({ delta: 1 });
    },
    //点击本地缓存按钮
    handleCache() {
      const that = this;
      let uploadCount = 0;
      //启动游标
      if (this.db == null) {
        Toast("本地数据库出错，请退出后重新进入！");
        return;
      }
      var objectStore = this.db.transaction("files_os").objectStore("files_os");
      objectStore.openCursor().onsuccess = function (event) {
        var cursor = event.target.result;
        if (cursor) {
          if (cursor.key.includes(that.taskId)) {
            uploadCount++;
          }
          cursor.continue();
        } else {
          console.log("没有更多数据了！");
          //此处模拟全部blob存储到数据库
          if (uploadCount != that.uploadTotal) {
            Toast("请将所需内容补充完整！");
            return;
          } else {
            //上报缓存数据
            that.uploadCacheData();
          }
        }
      };
    },
    //点击上报按钮
    handleSubmit() {
      this.isUploadDisable = true; //点击上传按钮禁止上传
      const that = this;
      let uploadCount = 0;
      //启动游标
      if (this.db == null) {
        Toast("本地数据库出错，请退出后重新进入！");
        this.isUploadDisable = false;
        return;
      }
      var objectStore = this.db.transaction("files_os").objectStore("files_os");
      objectStore.openCursor().onsuccess = function (event) {
        Toast("准备上传中，请稍后...");
        var cursor = event.target.result;
        // console.log(cursor)
        if (cursor) {
          if (cursor.key.includes(that.taskId)) {
            uploadCount++;
          }
          cursor.continue();
        } else {
          console.log("没有更多数据了！");
          //此处全部blob存储到数据库
          if (uploadCount != that.uploadTotal) {
            Toast("请将所需内容补充完整！");
            that.isUploadDisable = false;
            return;
          } else {
            //此处判断媒体情况选择是否正确
            let monitorData = that.$store.state.uploadMediaMonitor;
            const uploadData = monitorData.mediaArray;
            let isError = false;
            uploadData.forEach((el) => {
              if (
                (el.mediaAbnormalValues.length > 1 &&
                  el.mediaAbnormalValues.includes("0")) ||
                el.mediaAbnormalValues.length == 0
              ) {
                isError = true;
              }
            });

            if (isError) {
              Toast("媒体情况选择错误！");
              that.isUploadDisable = false;
              return;
            }

            //文件上传
            that.$bus.emit("file-upload");
            that.isUploadDisable = true; //点击上传按钮禁止上传
          }
        }
      };
    },
    //根据指定的key删除数据
    clearDBData(taskId) {
      const that = this;
      //启动游标
      if (this.db == null) return;
      var objectStore = this.db.transaction("files_os").objectStore("files_os");
      objectStore.openCursor().onsuccess = function (event) {
        var cursor = event.target.result;
        if (cursor) {
          if (cursor.key.includes(taskId)) {
            //删除
            that.handleDelData(cursor.key);
          }
          cursor.continue();
        } else {
          console.log("遍历完成！");
        }
      };
    },
    //从数据库中删除数据
    handleDelData(key) {
      const that = this;
      var request = this.db
        .transaction(["files_os"], "readwrite")
        .objectStore("files_os")
        .delete(key);

      request.onsuccess = function (event) {
        console.log("数据删除成功");
      };
    },
    //获取标准
    getCaptureStandard(id, token) {
      this.$axios
        .get("https://monitorapi.greatchinaoutdoor.com/job/monidataStandard", {
          params: {
            jobId: id,
          },
          headers: {
            user_token: token,
          },
        })
        .then((res) => {
          if (res.data.success == "true") {
            const data0 = res.data.data[0];

            this.jobStatus = data0.jobStatus;
            this.locationThreshold = data0.locationThreshold;
            this.lat = data0.lat;
            this.lng = data0.lng;

            this.valid = data0.valid;

            //监测要求
            this.requirement = data0.requirement;

            //需要上传文件的总数量 = 媒体数量 * 媒体下的标准数量
            this.uploadTotal =
              data0.mediaCount * data0.monitorData[0].fileStanArr.length;

            // asyncUploadValue 1: 允许 0 不允许
            this.isCeche = data0.asyncUploadValue == 1 ? true : false;
            //是否需要位置校验 1: 校验 0 不校验
            this.isLocation = data0.locationVarifyValue == 1 ? true : false;
            //是否支持选择本地文件
            this.isAsyncFile = data0.asyncFile == 1 ? true : false;

            //媒体 + 标准
            this.monitorList = data0.monitorData;

            //媒体数量
            this.mediaCount = data0.mediaCount;
            //项目id
            this.projectId = data0.projectId;
            //任务id
            this.taskId = data0.jobId;
            //作业标准
            this.standardList = data0.monitorData[0].fileStanArr;

            //准备需要提交的监测数据 start
            let monitorDataObj = {
              id: data0.jobId,
              mediaArray: [],
            };
            let mediaList = [];
            for (let i = 0; i < data0.monitorData.length; i++) {
              mediaList.push({
                mediaSequence: i + 1,
                mediaAbnormalValues: data0.monitorData[i].mediaAbnormalValues
                  ? data0.monitorData[i].mediaAbnormalValues
                  : ["0"],
                mediaOtherAbnormal: data0.monitorData[i].mediaOtherAbnormal
                  ? data0.monitorData[i].mediaOtherAbnormal
                  : undefined,
                mediaAbnormalRemark: undefined,
                fileArray: [],
              });

              data0.monitorData[i].fileStanArr.forEach((item, index) => {
                if (item.captureStandardModel.fileTypeValue == 2) {
                  let playerId = `player${i + 1}${index + 1}`;
                  this.$nextTick(() => {
                    //初始化视频播放器
                    this.initPlayer(
                      playerId,
                      item.captureStandardModel.exampleOssUrl
                    );
                  });
                }
              });
            }
            monitorDataObj.mediaArray = mediaList;
            this.$store.commit("setUploadMonitorData", monitorDataObj);
          } else {
            Toast(res.data.data.msg);
          }
        });
    },
    //水印标准
    getwatermarkConfig(id, token) {
      this.$axios
        .get("https://monitorapi.greatchinaoutdoor.com/job/watermarkContentInfo", {
          params: {
            jobId: id,
          },
          headers: {
            user_token: token,
          },
        })
        .then((res) => {
          if (res.data.success == "true") {
            this.$store.commit("setWatermarkConfig", res.data.data);
          } else {
            Toast(res.data.data.msg);
          }
        });
    },
    //上报监测任务
    uploadMonitorData(data) {
      Toast.loading({
        message: "上报中...",
        forbidClick: true,
        duration: 0,
      });
      this.$axios
        .post("https://monitorapi.greatchinaoutdoor.com/job/monitorData", data, {
          headers: {
            user_token: this.token,
          },
        })
        .then((res) => {
          Toast.clear();
          if (res.data.success == "true") {
            //此处操作 -- 清除对应数据库的数据，然后跳转页面
            this.clearDBData(this.taskId);
            Toast("数据上报成功！");
            setTimeout(() => {
              //跳转页面
              wx.miniProgram.redirectTo({
                url: "/packageA/pages/step3/step3?type=upload",
              });
            }, 1000);
          } else {
            Toast(res.data.data.msg);
            // this.isUploadDisable = false;
          }
        })
        .catch((err) => {
          Toast.clear();
          // this.isUploadDisable = false;
          Toast("请求出错了...");
        });
    },
    //上报缓存任务
    uploadCacheData() {
      let data = {
        id: this.taskId,
      };
      this.$axios
        .post("https://monitorapi.greatchinaoutdoor.com/job/localStorage", data, {
          headers: {
            user_token: this.token,
          },
        })
        .then((res) => {
          if (res.data.success == "true") {
            Toast("离线缓存成功");
            setTimeout(() => {
              //跳转页面
              wx.miniProgram.navigateTo({
                url: "/packageA/pages/step3/step3?type=cache",
              });
            }, 500);
          } else {
            Toast(res.data.data.msg);
          }
        })
        .catch((err) => {
          Toast("请求出错了...");
        });
    },
    //初始化播放器
    initPlayer(id, playUrl) {
      //实例播放
      let player = new Player({
        id: id,
        height: "100%",
        url: playUrl,
        controlPlugins: [play, fullscreen, flex],
      });
    },
    //媒体情况change
    handleMediaStatus(event, count) {
      let monitorData = this.$store.state.uploadMediaMonitor;
      monitorData.mediaArray[count].mediaAbnormalValues = event;
      //修改媒体情况
      this.$store.commit("setUploadMonitorData", monitorData);
    },
  },
  created() {
    if (this.$route.query.type && this.$route.query.type == "cache") {
      this.isCeche = false;
    }
    if (this.$route.query.id && this.$route.query.token) {
      this.taskId = this.$route.query.id;
      this.token = this.$route.query.token;
      //获取作业标准
      this.getCaptureStandard(this.$route.query.id, this.$route.query.token);
      //获取水印参数配置
      this.getwatermarkConfig(this.$route.query.id, this.$route.query.token);
    }
  },
  mounted() {
    const that = this;
    //打开数据库
    let request = window.indexedDB.open("files_db", 1);
    request.onerror = function () {
      Toast("本地数据库创建失败，请退出后重新进入！");
    };
    request.onsuccess = function () {
      that.db = request.result;
      //判断数据库是否存在
      if (that.db.objectStoreNames.contains("files_os")) {
        //展示写入的数据
        // getIDBData();
      }
    };
    request.onupgradeneeded = function (e) {
      // Grab a reference to the opened database
      let db = e.target.result;
      // Create an objectStore to store our videos in (basically like a single table)
      // including a auto-incrementing key
      let objectStore = db.createObjectStore("files_os", {
        keyPath: "id",
        // autoIncrement: true,
      });
      // 定义存储对象的数据项
      objectStore.createIndex("id", "id", { unique: true });
      objectStore.createIndex("taskId", "taskId"); //任务ID
      objectStore.createIndex("lng", "lng"); //经度
      objectStore.createIndex("lat", "lat"); //纬度
      objectStore.createIndex("createtime", "createtime"); //拍摄时间
      objectStore.createIndex("type", "type"); //文件类型 图片 or 视频
      objectStore.createIndex("fileBlob", "fileBlob"); //blob
    };
  },
};
</script>

<style lang="less" scoped>
.require-wrapper {
  padding: 10px 10px 0;
  background: #fff;
  .header {
    font-weight: 600;
    font-size: 18px;
    line-height: 40px;
    border-bottom: 1px solid #f4f4f4;
    position: relative;
    .circle {
      width: 14px;
      height: 14px;
      border-radius: 7px;
      background: #079a7b;
      display: inline-block;
      position: relative;
      margin-right: 4px;
      &::before {
        content: "";
        width: 8px;
        height: 8px;
        position: absolute;
        background: #fff;
        border-radius: 4px;
        top: 3px;
        left: 3px;
      }
    }
  }
  .require-content {
    font-size: 16px;
    padding: 6px 2px;
  }
}

.media-title {
  font-size: 20px;
  line-height: 40px;
  padding-left: 6px;
}
.filter-disabled {
  pointer-events: none;
  filter: opacity(0.6);
}
.task-card__wrapper {
  padding: 10px;
  .item {
    background: #fff;
    border-radius: 8px;
    padding: 6px 15px 15px;
    &.warning {
      background: #fceeee;
    }
    > h4 {
      font-size: 18px;
      line-height: 40px;
      border-bottom: 1px solid #f4f4f4;
      position: relative;
      .circle {
        width: 14px;
        height: 14px;
        border-radius: 7px;
        background: #079a7b;
        display: inline-block;
        position: relative;
        margin-right: 4px;
        &::before {
          content: "";
          width: 8px;
          height: 8px;
          position: absolute;
          background: #fff;
          border-radius: 4px;
          top: 3px;
          left: 3px;
        }
      }
    }
    .media-status--wrapper {
      padding-top: 12px;
      .radio-item {
        width: 40%;
        font-size: 14px !important;
        margin-bottom: 8px !important;
      }
    }
    .file-wrapper {
      min-height: 90px;
      display: flex;
      margin-top: 16px;
      padding: 0 16px;
      .image-box {
        position: relative;
        height: 160px;
        flex: 1;
        border-radius: 8px;
        display: flex;
        align-items: center;
        overflow: hidden;
        &.vertical {
          height: 320px;
        }
        > img {
          width: 100%;
        }
        .video-wrapper {
          width: 100%;
          height: 100%;
        }
        > video {
          width: 100%;
        }
        > .tips {
          position: absolute;
          width: 100%;
          line-height: 24px;
          text-align: center;
          bottom: 0;
          background: rgba(0, 0, 0, 0.6);
          color: #fff;
          font-size: 14px;
        }
      }
    }
    .warning-desc {
      line-height: 30px;
      font-size: 14px;
      color: #e55757;
      text-align: center;
      margin-bottom: -10px;
    }
    .ad-img_tips {
      text-align: center;
      font-size: 16px;
      margin-top: 10px;
      color: #999;
    }
  }
}
.operate-wrapper {
  margin: 20px 0;
  display: flex;
  justify-content: space-around;
  .btn {
    width: 100px;
    text-align: center;
    padding: 8px;
    color: #fff;
    border-radius: 24px;
    cursor: pointer;
    font-size: 16px;
    &.pre-btn {
      background: #fff;
      color: #079a7b;
      box-shadow: 0px 4px 12px 0px rgba(218, 218, 218, 0.68);
    }
    &.indexed-btn {
      background: url(https://wx.codc.demo4u.cn/wechatImg/db-btn.png) center
        no-repeat;
      background-size: 100% 100%;
      box-shadow: 0px 4px 8px 0px rgba(62, 187, 171, 0.85);
      &.disable {
        pointer-events: none;
        filter: opacity(0.5);
      }
    }
    &.next-btn {
      background: #5eb96e;
      background-size: 100% 100%;
      box-shadow: 0px 4px 8px 0px #5eb96e;
      &.disable {
        pointer-events: none;
        filter: opacity(0.5);
      }
    }
  }
}
</style>
